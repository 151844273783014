import { ComponentType, useEffect, useState } from "react"
import { Toaster } from "react-hot-toast"
import { useAuth } from "https://framer.com/m/hooks-cRhB.js"
import ComponentsLoading from "https://framer.com/m/Components-Loading-uUh0.js"
import { motion, AnimatePresence } from "framer-motion"
import { createStore } from "https://framer.com/m/framer/store.js@^1.0.0"
import { useIsInPreview } from "https://framer.com/m/framer/default-utils.js@^0.45.0"
import { useMatchMedia } from "https://framerusercontent.com/modules/dOR7zlSphW1zoS9pckZi/ihHhVzt0jY2Kilecgxa6/useMatchMedia.js"

import GeneralMadeInFramerBadge from "https://framer.com/m/Smart-Components-MadeInFramerBadge-OzD3.js@Hizsqgtlnd8tPf9V6MHI"

const useStore = createStore({
    user: null,
})

function Badge() {
    const isMobile = useMatchMedia("(max-width: 810px)")

    if (isMobile) {
        return null
    }

    const handleClick = () => {
        if (typeof window !== "undefined") {
            window.location.href = "https://framer.com/?via=lenderson"
        }
    }
    return (
        <GeneralMadeInFramerBadge
            variant="Day"
            style={{
                position: "fixed",
                bottom: 20,
                right: 20,
                zIndex: 9,
            }}
            onClick={handleClick}
            link="https://framer.com/?via=lenderson"
        />
    )
}

const BASE_URL = "https://curso.framer.website"

interface OverrideProps {
    badge?: boolean
    auth?: boolean
    mail?: boolean
}

function createPageOverride(
    Component,
    { badge, auth, mail = false }: OverrideProps
) {
    return (props) => {
        const { user } = useAuth()
        const isInPreview = useIsInPreview()

        if (typeof window !== "undefined") {
            const redirectPath = auth ? !user && "/404" : user && "/cursos"

            if (redirectPath) {
                window.location.href = BASE_URL + redirectPath
                return // Não precisamos renderizar mais nada após o redirecionamento
            }

            if (mail) {
                let localEmail = window.localStorage.getItem("emailForSignIn")
                if (!localEmail) {
                    window.location.href = BASE_URL + "/login"
                }
            }
        }

        if (isInPreview) {
            return <Component {...props} />
        }

        return (
            <AnimatePresence>
                {badge && <Badge />}
                <Toaster position="bottom-center" reverseOrder={false} />
                {auth && !user ? (
                    <ComponentsLoading {...props} />
                ) : (
                    <Component {...props} />
                )}
            </AnimatePresence>
        )
    }
}
export function onAuth(Component): ComponentType {
    return createPageOverride(Component, {
        auth: true,
        badge: true,
    })
}

export function onLogin(Component): ComponentType {
    return createPageOverride(Component, {
        auth: false,
        badge: true,
    })
}

export function onLoginMail(Component): ComponentType {
    return createPageOverride(Component, {
        auth: false,
        badge: true,
        mail: true,
    })
}

export function onSimplePage(Component): ComponentType {
    return (props) => {
        return (
            <AnimatePresence>
                <Badge />
                <Component {...props} />
            </AnimatePresence>
        )
    }
}
